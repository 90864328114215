import React, { useState, useEffect } from "react";
import Currency from "react-currency-formatter";
import Moment from "react-moment";
import moment from "moment";
import { savePayroll } from "../api/payroll-api";

import { useSnackbar } from "react-simple-snackbar";
import { et } from "date-fns/locale";

export default function EditPaystub(props) {
    const [regularEarnings, setRegularEarnings] = useState(props.paystub.e_regular || 0);
    const [eTotal, setETotal] = useState(props.paystub.e_total || 0);

    const [federalTax, setFederalTax] = useState(props.paystub.t_federal || 0);
    const [socialSecurity, setSocialSecurity] = useState(props.paystub.t_ssn || 0);
    const [medicare, setMedicare] = useState(props.paystub.t_medicare || 0);
    const [gaSit, setGaSit] = useState(props.paystub.t_gasit || 0);
    const [waMl, setWaMl] = useState(props.paystub.t_wa_ml || 0);
    const [waFl, setWaFl] = useState(props.paystub.t_wa_fl || 0);
    const [waLtc, setWaLtc] = useState(props.paystub.t_wa_ltc || 0);
    const [waWc, setWaWc] = useState(props.paystub.t_wa_wc || 0);
    const [taxTotal, setTaxTotal] = useState(props.paystub.t_total || 0);

    const [healthInsurance, setHealthInsurance] = useState(props.paystub.d_insurance || 0);
    const [dental, setDental] = useState(props.paystub.d_dental || 0);
    const [vision, setVision] = useState(props.paystub.d_vision || 0);
    const [traditional401k, setTraditional401k] = useState(props.paystub.d_401k || 0);
    const [rothIrs, setRothIrs] = useState(props.paystub.d_irs || 0);
    const [carolinaState, setCarolinaState] = useState(props.paystub.t_nc || 0);

    const [deductionsTotal, setDeductionsTotal] = useState(props.paystub.d_total || 0);

    const [netTotal, setNetTotal] = useState(props.paystub.net_pay || 0);

    const [openSnackbar, closeSnackbar] = useSnackbar();

    const toUTCFormat = (d) => {
        return moment.utc(moment(d)).format("MM/DD/YYYY");
    };

    const toUTCDBFormat = (d) => {
        return moment.utc(moment(d)).format("YYYY-MM-DD");
    };

    const formatToTwoDecimals = (value: any): number => {
        const num = Number(value); // Ensure value is a number
        return isNaN(num) ? 0 : Number(num.toFixed(2)); // Handle invalid numbers
    };

    const submitDetails = () => {
        const payrollData = { ...props.paystub };
        console.log(payrollData);

        console.log(payrollData?.check_date);
        console.log(payrollData?._d_start);
        console.log(payrollData?._d_end);

        // Date Fixes
        if (payrollData?.check_date) {
            payrollData.check_date = toUTCDBFormat(payrollData?.check_date);
        }

        if (payrollData?._d_start) {
            payrollData._d_start = toUTCDBFormat(payrollData?._d_start);
        }

        if (payrollData?._d_end) {
            payrollData._d_end = toUTCDBFormat(payrollData?._d_end);
        }
        console.log(taxTotal);

        // Update

        payrollData.e_regular = formatToTwoDecimals(regularEarnings);
        payrollData.e_total = formatToTwoDecimals(eTotal);

        payrollData.t_federal = formatToTwoDecimals(federalTax);
        payrollData.t_ssn = formatToTwoDecimals(socialSecurity);
        payrollData.t_medicare = formatToTwoDecimals(medicare);
        payrollData.t_gasit = formatToTwoDecimals(gaSit);
        payrollData.t_wa_ml = formatToTwoDecimals(waMl);
        payrollData.t_wa_fl = formatToTwoDecimals(waFl);
        payrollData.t_wa_ltc = formatToTwoDecimals(waLtc);
        payrollData.t_wa_wc = formatToTwoDecimals(waWc);
        payrollData.t_nc = formatToTwoDecimals(carolinaState);
        payrollData.t_total = formatToTwoDecimals(taxTotal);

        payrollData.d_insurance = formatToTwoDecimals(healthInsurance);
        payrollData.d_dental = formatToTwoDecimals(dental);
        payrollData.d_vision = formatToTwoDecimals(vision);
        payrollData.d_401k = formatToTwoDecimals(traditional401k);
        payrollData.d_irs = formatToTwoDecimals(rothIrs);
        payrollData.d_total = formatToTwoDecimals(deductionsTotal);

        payrollData.net_pay = formatToTwoDecimals(netTotal);

        console.log(payrollData);

        savePayroll(payrollData).then((result: any) => {
            console.log(result);
            openSnackbar(result?.error ? result?.error.sqlMessage : "Done");
        });
    };

    useEffect(() => {
        // Compute values before setting state
        const newETotal = formatToTwoDecimals(regularEarnings);
        const newTaxTotal = formatToTwoDecimals(Number(federalTax) + Number(socialSecurity) + Number(medicare) + Number(gaSit) + Number(waMl) + Number(waFl) + Number(waLtc) + Number(waWc)+ Number(carolinaState));
        const newDeductionsTotal = formatToTwoDecimals(Number(healthInsurance) + Number(dental) + Number(vision) + Number(traditional401k) + Number(rothIrs) );
        const newNetTotal = formatToTwoDecimals(newETotal - (newTaxTotal + newDeductionsTotal));

        // Update state with computed values
        setETotal(newETotal);
        setTaxTotal(newTaxTotal);
        setDeductionsTotal(newDeductionsTotal);
        setNetTotal(newNetTotal);

        // Debugging logs
        console.warn("eTotal:", newETotal);
        console.warn("taxTotal:", newTaxTotal);
        console.warn("deductionsTotal:", newDeductionsTotal);
        console.warn("netTotal:", newNetTotal);
    }, [regularEarnings, federalTax, socialSecurity, medicare, gaSit, waMl, waFl, waLtc, waWc, healthInsurance, dental, vision, traditional401k, rothIrs, carolinaState]);

    return (
        <div className="section-to-print">
            <div className="px-0 py-0 sm:px-0 lg:px-0 ">
                <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                    <div className="flex justify-left">
                        <img className="h-20 mx-auto mt-0 ml-0" src="/mti-logo.png" alt="MTINC" />
                    </div>
                </div>

                <div className="px-0 py-1 sm:flex sm:items-center sm:px-0 lg:px-0 ">
                    <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold text-gray-900 text-center-x">{props?.employee?.name}</h1>
                        <p className="mt-0 text-xs text-gray-700 text-center-x">SSN - {props?.employee?.masked_ssn}</p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <p className="mt-0 text-xs text-right text-gray-700">
                            Check Date -{" "}
                            <Moment filter={toUTCFormat} local>
                                {props.paystub.check_date}
                            </Moment>
                            <br />
                            Check Period -{" "}
                            <Moment filter={toUTCFormat} local>
                                {props.paystub._d_start}
                            </Moment>{" "}
                            to{" "}
                            <Moment filter={toUTCFormat} local>
                                {props.paystub._d_end}
                            </Moment>
                            <br />
                        </p>
                    </div>
                </div>

                <hr className="pb-2 text-bold"></hr>

                <div className="flex flex-col mt-2">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <h2 className="pb-1 font-bold">Earnings</h2>
                            <div className="w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">Type</th>
                                            <th className="px-3 py-2 text-sm font-semibold text-left text-gray-900">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">Regular</td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" placeholder="Enter amount" value={regularEarnings} onChange={(e) => setRegularEarnings(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">Total Earnings</td>
                                            <td className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                <Currency quantity={eTotal} currency="USD" />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <h2 className="pb-1 font-bold">Taxes</h2>
                            <div className="w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">
                                                Type
                                            </th>

                                            <th scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">Federal Income Tax</td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" placeholder="Enter amount" value={federalTax} onChange={(e) => setFederalTax(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">Social Security</td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" placeholder="Enter amount" value={socialSecurity} onChange={(e) => setSocialSecurity(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">Medicare</td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" placeholder="Enter amount" value={medicare} onChange={(e) => setMedicare(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">GA SIT</td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" value={gaSit} onChange={(e) => setGaSit(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">WA ML</td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" value={waMl} onChange={(e) => setWaMl(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">WA FL </td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" value={waFl} onChange={(e) => setWaFl(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">WA LTC</td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" value={waLtc} onChange={(e) => setWaLtc(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">Carolina </td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" value={carolinaState} onChange={(e) => setCarolinaState(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">WA WC</td>
                                            <td className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <input type="number" className="px-2 py-1 text-sm border" value={waWc} onChange={(e) => setWaWc(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">
                                                Total Taxes
                                            </td>
                                            <td className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                <Currency quantity={taxTotal} currency="USD" />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <h2 className="pb-1 font-bold">Employee Deductions</h2>
                            <div className="w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">
                                                Type
                                            </th>

                                            <th scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td className="py-2 pl-4 text-sm font-normal text-left text-gray-900">Health Insurance</td>
                                            <td>
                                                <input type="number" className="px-2 py-1 text-sm border" value={healthInsurance} onChange={(e) => setHealthInsurance(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 pl-4 text-sm font-normal text-left text-gray-900">Dental Pre-Tax</td>
                                            <td>
                                                <input type="number" className="px-2 py-1 text-sm border" value={dental} onChange={(e) => setDental(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 pl-4 text-sm font-normal text-left text-gray-900">Vision Pre-Tax</td>
                                            <td>
                                                <input type="number" className="px-2 py-1 text-sm border" value={vision} onChange={(e) => setVision(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 pl-4 text-sm font-normal text-left text-gray-900">Traditional 401(k)</td>
                                            <td>
                                                <input type="number" className="px-2 py-1 text-sm border" value={traditional401k} onChange={(e) => setTraditional401k(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 pl-4 text-sm font-normal text-left text-gray-900">ROTH / IRS</td>
                                            <td>
                                                <input type="number" className="px-2 py-1 text-sm border" value={rothIrs} onChange={(e) => setRothIrs(parseFloat(e.target.value) || 0)} />
                                            </td>
                                        </tr>
                                      
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td scope="col" className="py-0 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[500px]">
                                                Total Deductions
                                            </td>
                                            <td className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                <Currency quantity={deductionsTotal} currency="USD" />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-0 align-middle md:px-6 lg:px-8">
                            <div className="w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full text-white bg-gray-700 divide-y divide-gray-300">
                                    <tfoot>
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-left text-md font-extrabold  sm:pl-6 w-[500px]">
                                                Net Pay
                                            </td>
                                            <td scope="col" className="px-3 py-2 font-semibold text-left text-md">
                                                <Currency quantity={netTotal} currency="USD" />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div className="flex justify-center mt-4">
                                <button className="px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600" onClick={submitDetails}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
