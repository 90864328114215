import React, { useEffect, useState } from "react";
import { listOfEmployeesValidPaystub } from "../api/consultants-api";
import Breadcrums from "../components/breadcrums";
import PaystubToolbar from "../blocks/paystub-toolbar";
import { Link } from "react-router-dom";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { getFortnightPayroll, saveBulkPayroll, savePayroll } from "../api/payroll-api";
import { useSnackbar } from "react-simple-snackbar";
import { Payroll, PayrollModel } from "../model";
import moment from "moment";
import { nanoid } from "nanoid";
import Moment from "react-moment";
// import { format, toDate, parseISO } from "date-fns";
import Currency from "react-currency-formatter";

const breadcrumLinks = [
    { name: "Payroll", href: "/payroll", current: false },
    { name: "New Paystub", href: "/payroll/new", current: true },
];

export default function NewPayroll() {
    const [people, setPeople] = useState([]);

    const [dates, setDates] = useState({
        checkDate: moment(),
        startDate: moment(),
        endDate: moment(),
    });

    const { register, control, handleSubmit, reset, trigger, setError, watch, setValue, getValues } = useForm<Payroll>();
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "consultants",
    });
    const [openSnackbar, closeSnackbar] = useSnackbar();
    // const watchFieldArray = watch("consultants");
    const [isLoading, setIsLoading] = useState(false);
    // useEffect(() => {

    //     const { unsubscribe } = watch((value) => {

    //         //console.log('watch',value,watchFieldArray)
    //         if (value.consultants && value.consultants.length > 0) {
    //             value.consultants?.forEach((data: any) => {
    //                 //   data.e_regular = Number(data.hrs) * Number(data.rate)
    //                 data.e_total = Number(data.e_regular).toFixed(2);
    //                 data.d_total = (Number(data.d_insurance) + Number(data.d_dental) + Number(data.d_vision) + Number(data.d_401k) + Number(data.d_irs)).toFixed(2);
    //                 data.t_total = (Number(data.t_federal) + Number(data.t_ssn) + Number(data.t_medicare) + Number(data.t_gasit) + Number(data.t_wa_ml) + Number(data.t_wa_fl) + Number(data.t_wa_ltc) + Number(data.t_wa_wc)).toFixed(2);
    //                 data.net_pay = (Number(data.e_total) - Number(data.d_total) - Number(data.t_total)).toFixed(2);
    //             });
    //         }
    //     });
    //     return () => unsubscribe();
    // }, [watch]);
    const updateNetPay = (index: number, field: keyof Payroll["consultants"][0], newValue: any) => {
        let data = getValues().consultants;
        setValue(`consultants.${index}.${field}`, newValue);

        //   data.e_regular = Number(data.hrs) * Number(data.rate)
        data[index].e_total = Number(data[index].e_regular).toFixed(2) as unknown as number;
        data[index].d_total = (Number(data[index].d_insurance) + Number(data[index].d_dental) + Number(data[index].d_vision) + Number(data[index].d_401k) + Number(data[index].d_irs)).toFixed(2) as unknown as number;
        data[index].t_total = (Number(data[index].t_federal) + Number(data[index].t_ssn) + Number(data[index].t_medicare) + Number(data[index].t_gasit) + Number(data[index].t_wa_ml) + Number(data[index].t_wa_fl) + Number(data[index].t_wa_ltc) + Number(data[index].t_wa_wc) + Number(data[index].t_nc)).toFixed(2) as unknown as number;
        data[index].net_pay = (Number(data[index].e_total) - Number(data[index].d_total) - Number(data[index].t_total)).toFixed(2) as unknown as number;
        setValue(`consultants.${index}`, data[index]);
    };

    useEffect(() => {
        listOfEmployeesValidPaystub({ activeStatus: 1 }).then((data) => {
            setPeople(data);
            console.log(data);
            setPeople(data);
            if (data.length > 0 && fields.length === 0) {
                let payroll: Payroll["consultants"] = [];
                data?.forEach((person, pIdx) => {
                    //console.log(person.unstructured);

                    let definedPayStub = { hrs: 0, e_regular: 0, e_total: 0, t_federal: 0, t_ssn: 0, t_medicare: 0, t_gasit: 0, t_wa_ml: 0, t_wa_fl: 0, t_wa_ltc: 0, t_wa_wc: 0, t_nc: 0, t_total: 0,  d_insurance: 0, d_dental: 0, d_vision: 0, d_401k: 0, d_irs: 0, d_total: 0, net_pay: 0, rate: 0 };
                    if (person.unstructured) {
                        var pay = JSON.parse(person.unstructured);
                        definedPayStub = Object.assign({}, pay?.paystubAnnexure);
                        definedPayStub.rate = person.rate;

                        //console.log(definedPayStub);
                    }

                    let setPayroll: Payroll["consultants"][0] = getPayStub(person, definedPayStub);

                    payroll.push(setPayroll);
                });
                //console.log('calll',payroll)
                setValue("consultants", payroll);
            }
        });
    }, []);

    const getPayStub = (person, definedPayStub) => {
        return {
            uuid: person.uuid,
            name: person.name,
            selected: person.payroll_process,

            check_date: dates.checkDate.format("YYYY-MM-DD"),
            _d_start: dates.startDate.format("YYYY-MM-DD"),
            _d_end: dates.endDate.format("YYYY-MM-DD"),

            hrs: 80, //FIXME
            check_no: "Cash", //FIXME

            payment_type: "Regular",

            e_regular: definedPayStub ? Number( definedPayStub?.e_regular | 0) : 0,
            e_total: definedPayStub ? Number(definedPayStub?.e_total | 0) : 0,

            t_federal: definedPayStub ? Number(definedPayStub?.t_federal | 0) : 0,
            t_ssn: definedPayStub ? Number(definedPayStub?.t_ssn | 0) : 0,
            t_medicare: definedPayStub ? Number(definedPayStub?.t_medicare | 0) : 0,

            t_gasit: definedPayStub ? Number(definedPayStub?.t_gasit | 0) : 0,
            t_wa_ml: definedPayStub ? Number(definedPayStub?.t_wa_ml | 0) : 0,
            t_wa_fl: definedPayStub ? Number(definedPayStub?.t_wa_fl | 0) : 0,
            t_wa_ltc: definedPayStub ? Number(definedPayStub?.t_wa_ltc | 0) : 0,
            t_wa_wc: definedPayStub ? Number(definedPayStub?.t_wa_wc | 0) : 0,
            t_nc: definedPayStub ? Number(definedPayStub?.t_nc | 0) : 0,


            t_total: definedPayStub ? Number(definedPayStub?.t_total | 0) : 0,

            d_insurance: definedPayStub ? Number(definedPayStub?.d_insurance | 0) : 0,
            d_dental: definedPayStub ? Number(definedPayStub?.d_dental | 0) : 0,
            d_vision: definedPayStub ? Number(definedPayStub?.d_vision | 0) : 0,
            d_401k: definedPayStub ? Number(definedPayStub?.d_401k | 0) : 0,
            d_irs: definedPayStub ? Number(definedPayStub?.d_irs | 0) : 0,
            d_total: definedPayStub ? Number(definedPayStub?.d_total | 0) : 0,

            net_pay: definedPayStub ? Number(definedPayStub?.net_pay  | 0) : 0,
            rate: definedPayStub ? Number(definedPayStub?.rate | 0) : 0,
        };
    };
    useEffect(
        function () {
            // Load data from finance If exist & bind to respective records / rows
            try {
                // setIsLoading(true);
                getFortnightPayroll({
                    checkDate: dates.startDate,
                    // startDate: defaultStartDate,
                    employee: "",
                }).then(function (data: any[]) {
                    setIsLoading(false);

                    people.forEach((person: any, pIdx) => {
                        let personPayroll = data.find((e) => e.uuid === person.uuid);
                        //if present then
                        if (personPayroll && personPayroll.uuid) {
                            let updatedPersonPayroll = {
                                uuid: person.uuid,
                                id: personPayroll.id, //  to be discussed
                                name: person.name,
                                rate: person.rate,
                                selected: person.payroll_process,

                                check_date: dates.checkDate.format("YYYY-MM-DD"),
                                _d_start: dates.startDate.format("YYYY-MM-DD"),
                                _d_end: dates.endDate.format("YYYY-MM-DD"),

                                hrs: personPayroll.hrs, //FIXME
                                check_no: "Cash", //FIXME

                                payment_type: "Regular",

                                e_regular: personPayroll.e_regular,
                                e_total: personPayroll.e_total,

                                t_federal: personPayroll.t_federal,
                                t_ssn: personPayroll.t_ssn,
                                t_medicare: personPayroll.t_medicare,

                                t_gasit: personPayroll.t_gasit,
                                t_wa_ml: personPayroll.t_wa_ml,
                                t_wa_fl: personPayroll.t_wa_fl,
                                t_wa_ltc: personPayroll.t_wa_ltc,
                                t_wa_wc: personPayroll.t_wa_wc,
                                t_nc: personPayroll.t_nc,


                                t_total: personPayroll.t_total,

                                d_insurance: personPayroll.d_insurance,
                                d_dental: personPayroll.d_dental,
                                d_vision: personPayroll.d_vision,
                                d_401k: personPayroll.d_401k,
                                d_irs: personPayroll.d_irs,
                                d_total: personPayroll.d_total,

                                net_pay: personPayroll.net_pay,
                            };
                            update(
                                fields.findIndex((e: any) => e.uuid === personPayroll.uuid),
                                updatedPersonPayroll
                            );
                        }
                        // if not present
                        else {
                            let definedPayStub = { hrs: 0, e_regular: 0, e_total: 0, t_federal: 0, t_ssn: 0, t_medicare: 0, t_gasit: 0, t_wa_ml: 0, t_wa_fl: 0, t_wa_ltc: 0, t_wa_wc: 0, t_total: 0, d_insurance: 0, d_dental: 0, d_vision: 0, d_401k: 0, d_irs: 0, d_total: 0, net_pay: 0, rate: 0 };
                            if (person.unstructured) {
                                var pay = JSON.parse(person.unstructured);
                                definedPayStub = Object.assign({}, pay?.paystubAnnexure);
                                definedPayStub.rate = person.rate;
                                //console.log(definedPayStub);
                            }
                            update(
                                fields.findIndex((e: any) => e.uuid === person.uuid),
                                getPayStub(person, definedPayStub)
                            );
                        }
                        // payroll.push(setPayroll);
                    });
                });
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        },
        [dates]
    );

    const payrollToolbarHandler = async (option) => {
        if (option.value == 0) {
            setDates({ ...dates, checkDate: moment().endOf("month"), startDate: moment().date(1), endDate: moment().date(15) });
        } else if (option.value == 1) {
            setDates({ ...dates, checkDate: moment().add(1, "month").date(15), startDate: moment().date(16), endDate: moment().endOf("month") });
        } else if (option.value == -1) {
            setDates({ ...dates, checkDate: moment().date(15), startDate: moment().subtract(1, "month").date(16), endDate: moment().subtract(1, "month").endOf("month") });
        } else if (option.value == -2) {
            setDates({ ...dates, checkDate: moment().subtract(1, "month").endOf("month"), startDate: moment().subtract(1, "month").date(1), endDate: moment().subtract(1, "month").date(15) });
        } else if (option.value == -3) {
            setDates({ ...dates, checkDate: moment().subtract(1, "month").date(15), startDate: moment().subtract(2, "month").date(16), endDate: moment().subtract(2, "month").endOf("month") });
        } else if (option.value == -4) {
            setDates({ ...dates, checkDate: moment().subtract(2, "month").endOf("month"), startDate: moment().subtract(2, "month").date(1), endDate: moment().subtract(2, "month").date(15) });
        } else if (option.value == -5) {
            setDates({ ...dates, checkDate: moment().subtract(2, "month").date(15), startDate: moment().subtract(3, "month").date(16), endDate: moment().subtract(3, "month").endOf("month") });
        } else if (option.value == -6) {
            setDates({ ...dates, checkDate: moment().subtract(3, "month").endOf("month"), startDate: moment().subtract(3, "month").date(1), endDate: moment().subtract(3, "month").date(15) });
        }
    };

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        //console.log(data);

        data.consultants.forEach((e, eIdx) => {
            e.check_date = dates.checkDate.format("YYYY-MM-DD");
            e._d_start = dates.startDate.format("YYYY-MM-DD");
            e._d_end = dates.endDate.format("YYYY-MM-DD");
        });

        //console.log(data.consultants);
        if (data.consultants && data.consultants.length > 0) {
            data.consultants.forEach((person: any) => {
                const { name, selected, check_no, rate, ...payrollData } = person;
                if (person.selected) {
                    savePayroll(payrollData).then((result: any) => {
                        //console.log(result);
                        openSnackbar(result?.error ? result?.error.sqlMessage : "Done");
                    });
                }
            });
        }
    };
    const [selectedButton, setSelectedButton] = useState(true); // Earlier Reset

    const handleButtonClick = () => {
        const newSelectionState = !selectedButton;
        setSelectedButton(newSelectionState);

        let data = getValues().consultants;

        // Update the consultants' selected status immutably
        // setConsultants((prevConsultants) =>
        //     prevConsultants.map((consultant) => ({
        //         ...consultant,
        //         selected: newSelectionState,
        //     }))
        // );

        if (newSelectionState) {
            data.forEach((element) => {
                element.selected = true;
            });
        } else {
            data.forEach((element) => {
                element.selected = false;
            });
        }
    };

    // Event handler for button clicks
    /*const handleButtonClick = (buttonName: string) => {
        setSelectedButton(buttonName);

        let data = getValues().consultants;

        if (buttonName === "SelectAll") {
            data.forEach((element) => {
                element.selected = true;
            });
        } else if (buttonName === "UnSelect") {
            data.forEach((element) => {
                element.selected = false;
            });
        } else if (buttonName === "Reset") {
            data.forEach((element) => {
                const consultant = people.find((e: any) => e.uuid === element.uuid) as any;
                if (consultant) {
                    element.selected = consultant.payroll_process;
                }
            });
        }
    };*/

    return (
        <div>
            <Breadcrums breadcrumLinks={breadcrumLinks} module={"Payroll"} />

            <div className="px-4 py-6 sm:px-6 lg:px-8">
                <div className="mb-6 sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        {/* <h1 className="text-xl font-semibold text-gray-900">Users</h1> */}
                        <h1 className="text-2xl font-semibold text-gray-900">Paystub Details</h1>
                        <p className="mt-2 text-sm text-gray-700"></p>

                        <p className="mt-2 text-sm text-gray-700">
                            Check Date Range :<Moment format="YYYY/MM/DD">{dates.startDate}</Moment> to <Moment format="YYYY/MM/DD">{dates.endDate}</Moment>
                        </p>

                        <p className="mt-2 text-sm text-gray-700">
                            Check Date : <Moment format="YYYY/MM/DD">{dates.checkDate}</Moment>
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
                </div>

                <PaystubToolbar handler={payrollToolbarHandler} />
            </div>
            {!isLoading && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="px-4 py-0 pt-2 sm:px-6 lg:px-8">
                            <div className="flex flex-col mt-0">
                                <div className="mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-0 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-500">
                                                    <tr className="">
                                                        <th className="bg-gray-100">
                                                            <div className="inline-flex py-5 pb-6 shadow-xs px-7" role="group">
                                                                <label className="flex items-center px-1 py-2 text-sm font-medium focus:text-white dark:border-white dark:text-white dark:hover:text-white ">
                                                                    <input type="checkbox" className="mr-2 accent-gray-900" checked={selectedButton === true} onChange={() => handleButtonClick()} />
                                                                </label>
                                                            </div>{" "}
                                                        </th>
                                                        {/* py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 */}
                                                        <th scope="col" className="py-4 pl-4 pr-3 text-sm font-extrabold text-left text-gray-900 bg-gray-50 whitespace-nowrap sm:pl-6">
                                                            Employee
                                                        </th>
                                                        <th className="bg-gray-100">Hours & Earning</th>
                                                        <th className="bg-gray-200">Taxes</th>
                                                        <th className="bg-gray-300">Deductions</th>
                                                        <th className="block w-[60px] bg-gray-500 text-white">Net Pay</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {fields.map((person: any, personIdx) => (
                                                        <tr key={nanoid()} className={personIdx % 2 === 0 ? undefined : "bg-gray-0"}>
                                                            <td className="py-4 pl-4 pr-3 text-sm font-normal text-gray-900 whitespace-nowrap sm:pl-6">
                                                                <div className="flex items-center">
                                                                    <input {...register(`consultants.${personIdx}.selected`)} key={nanoid()} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                </div>
                                                            </td>

                                                            <td className="py-4 pl-4 pr-3 text-sm font-normal text-gray-900 whitespace-nowrap sm:pl-6">{person.name}</td>

                                                            <td className="px-2 bg-gray-100">
                                                                <div className="grid gap-1 md:grid-cols-1">
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>Hours</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.hrs`)} onChange={(e) => updateNetPay(personIdx, "hrs", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300 whitespace-nowrap" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>Regular Earnings</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.e_regular`)} onChange={(e) => updateNetPay(personIdx, "e_regular", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300 whitespace-nowrap" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td className="px-2 bg-gray-200">
                                                                <div className="grid gap-1 md:grid-cols-4">
                                                                    <div>
                                                                        <label htmlFor="large-input" className="w-full text-xs whitespace-nowrap">
                                                                            <small>Federal Income Tax</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.t_federal`)} onChange={(e) => updateNetPay(personIdx, "t_federal", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>Social Security</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.t_ssn`)} onChange={(e) => updateNetPay(personIdx, "t_ssn", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>Medicare</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.t_medicare`)} onChange={(e) => updateNetPay(personIdx, "t_medicare", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>GA SIT</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.t_gasit`)} onChange={(e) => updateNetPay(personIdx, "t_gasit", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>WA ML</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.t_wa_ml`)} onChange={(e) => updateNetPay(personIdx, "t_wa_ml", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>WA FL</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.t_wa_fl`)} onChange={(e) => updateNetPay(personIdx, "t_wa_fl", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>

                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>WA LTC</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.t_wa_ltc`)} onChange={(e) => updateNetPay(personIdx, "t_wa_ltc", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>

                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>WA WC</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.t_wa_wc`)} onChange={(e) => updateNetPay(personIdx, "t_wa_wc", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>

                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small> North Carolina</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.t_nc`)} onChange={(e) => updateNetPay(personIdx, "t_nc", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td className="px-2 bg-gray-300">
                                                                <div className="grid gap-1 md:grid-cols-3">
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>Health Insurance</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.d_insurance`)} onChange={(e) => updateNetPay(personIdx, "d_insurance", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            <small>Dental Pre-Tax</small>
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.d_dental`)} onChange={(e) => updateNetPay(personIdx, "d_dental", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            Vision Pre-Tax
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.d_vision`)} onChange={(e) => updateNetPay(personIdx, "d_vision", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            401(k)
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.d_401k`)} onChange={(e) => updateNetPay(personIdx, "d_401k", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                            ROTH
                                                                        </label>
                                                                        <input type="text" {...register(`consultants.${personIdx}.d_irs`)} onChange={(e) => updateNetPay(personIdx, "d_irs", e.target.value)} className="block w-full p-1 text-sm text-right text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                                    </div>
                                                                    
                                                                </div>
                                                            </td>

                                                            <td className="text-white bg-gray-500">
                                                                <p className="block w-full pl-1 text-xs font-bold">
                                                                    {/* <label {...register(`consultants.${personIdx}.net_pay`)} /> */}
                                                                    <input type="text" {...register(`consultants.${personIdx}.net_pay`)} disabled={true} className="block w-full p-1 text-sm text-right text-white bg-transparent border-0 border-transparent" placeholder="" />
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr className="font-bold bg-gray-300">
                                                        <td colSpan={2} className="py-4 pl-4 pr-3 text-sm text-left text-gray-900 whitespace-nowrap sm:pl-6">
                                                            Total
                                                        </td>
                                                        <td className="py-4 pl-4 pr-1 text-sm text-right text-gray-900 whitespace-nowrap sm:pl-5">
                                                            {" "}
                                                            <Currency quantity={fields?.reduce((acc: number, person: { e_total?: string | number }) => acc + (typeof person.e_total === "number" ? person.e_total : parseFloat(person.e_total || "0")), 0).toFixed(2)} currency="USD" />{" "}
                                                        </td>
                                                        <td className="py-4 pl-4 pr-1 text-sm text-right text-gray-900 whitespace-nowrap sm:pl-5">
                                                            <Currency quantity={fields?.reduce((acc: number, person: { t_total?: string | number }) => acc + (typeof person.t_total === "number" ? person.t_total : parseFloat(person.t_total || "0")), 0).toFixed(2)} currency="USD" />{" "}
                                                        </td>
                                                        <td className="py-4 pl-4 pr-1 text-sm text-right text-gray-900 whitespace-nowrap sm:pl-5">
                                                            <Currency quantity={fields?.reduce((acc: number, person: { d_total?: string | number }) => acc + (typeof person.d_total === "number" ? person.d_total : parseFloat(person.d_total || "0")), 0).toFixed(2)} currency="USD" />{" "}
                                                        </td>
                                                        <td className="py-4 pl-4 pr-1 text-sm text-right text-gray-900 whitespace-nowrap sm:pl-5">
                                                            <Currency quantity={fields.reduce((acc, person) => acc + (Number(person.net_pay) || 0), 0).toFixed(2)} currency="USD" />{" "}
                                                        </td>
                                                    </tr>

                                                    {/* <tr className="font-bold bg-gray-300">
                                                        <td colSpan={5} className="py-4 pl-4 pr-3 text-sm text-left text-gray-900 whitespace-nowrap sm:pl-6">
                                                            Total Deductions:
                                                        </td>
                                                    </tr>

                                                    <tr className="font-bold bg-gray-300">
                                                        <td colSpan={5} className="py-4 pl-4 pr-3 text-sm text-left text-gray-900 whitespace-nowrap sm:pl-6">
                                                            Total Taxes:
                                                        </td>
                                                    </tr>

                                                    <tr className="font-bold bg-gray-300">
                                                        <td colSpan={5} className="py-4 pl-4 pr-3 text-sm text-left text-gray-900 whitespace-nowrap sm:pl-6">
                                                            Total
                                                        </td>
                                                    </tr> */}
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end px-4 py-0 pt-10 pb-10 sm:px-6 lg:px-8">
                            <Link to="/payroll">
                                <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Cancel
                                </button>
                            </Link>
                            <button type="submit" className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Save
                            </button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}
