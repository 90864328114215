export async function getDashboardFinanceStats() {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/finance/aggregate?_fields=payment");
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getDashboardEmployeesStats() {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant/distinct?_where=(active,eq,1)");
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getProjectCountStats() {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/project_master/distinct?_where=(active,eq,1)");
        return await response.json();
    } catch (error) {
        return [];
    }
}
export async function getbillStats() {
    // console.log(filters);
    try {
        let _q =
            "SELECT   sum(project.rate * finance.hrs) as bill from finance LEFT JOIN consultant ON consultant.uuid =finance.uuid LEFT JOIN project ON consultant.uuid = project.uuid  AND _d BETWEEN project.start_dt AND project.end_dt LEFT JOIN project_master ON project.pid = project_master.pid  ";
        _q += " where 1=1 ";

        

        console.log("1", _q);

        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}