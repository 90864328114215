import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { Link } from "react-router-dom";
import { listOfPayroll } from "../api/payroll-api";
import DatePicker from "react-datepicker";
import { Filters } from "@tanstack/react-table";
import { nanoid } from "nanoid";
import { addUpdateConsultant } from "../api/consultants-api";
import moment from "moment";
import classNames from "classnames";
import { blueBgClr } from "../constants/common";

export type FilterProps = {
    people: any;
    applyFilters: any;
    dateLabel?: string;
};

export default function CommonFilters(props: FilterProps) {
    const { register, handleSubmit, setValue, getValues } = useForm();
    const [openSnackbar, closeSnackbar] = useSnackbar();

    const defaultStartDate = moment().toDate();
    // moment().subtract(15, "d").toDate();

    const [payrolldata, setPayrolladata] = useState([]);

    const [filteredCheckDate, setFilteredCheckDate] = useState(defaultStartDate);

    const [filters, setFilters] = useState({
        checkDate: defaultStartDate, //.format("DD-MM-YYYY")
        employee: "",
        groupedUUID: false,
    });

    function applyFilters(filters): any {
        setFilters({ ...filters, employee: getValues("employee") });
        setFilteredCheckDate(filters.checkDate);
        props.applyFilters(filters);
    }

    const onSubmit = (data, e) => {
        // const consultantData = {
        //     // id: props.emp.id,
        // };
        // console.log(consultantData);
        // addUpdateConsultant(consultantData).then((result) => {
        //     openSnackbar("Consultant Saved.");
        // });
    };
    useEffect(() => {
        // you can do async server request and fill up form
        setTimeout(() => {
            //   setValue("name", props.emp.name);
        });
    }, [setValue]);

    return (
        <>
            {/* <div className="py-0">Filters: User, Period, Financial Year</div> */}
            <div className="pt-0 space-y-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                        <div className="md:grid md:grid-cols-2 md:gap-6">
                            <div className="mt-5 md:mt-0 md:col-span-2">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-1">
                                        <label htmlFor="employee" className="block text-sm font-medium text-gray-700">
                                            Employee
                                        </label>
                                        <select {...register("employee", { required: false, onChange: (e) => setFilters({ ...filters, employee: e.target.value }) })} id="employee" multiple={false} size={1} name="employee" className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filters.employee}>
                                            <option
                                                value={""}
                                                key={nanoid()}
                                                onChange={(e) => {
                                                    setFilters({ ...filters, employee: "" });
                                                }}
                                            >
                                                All
                                            </option>
                                            {props.people.map((p: any, pIdx: any) => (
                                                <option value={p.uuid} key={nanoid()}>
                                                    {p.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                            {props.dateLabel || "Month-Year"}
                                        </label>
                                        <DatePicker dateFormat="MM/yyyy" showMonthYearPicker className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" selected={filters.checkDate} onChange={(date) => setFilters({ ...filters, checkDate: date })} />
                                        <span className="text-xs text-gray-400">mm/yyyy</span>
                                    </div>

                                    <div className="pt-6">
                                        <button type="button" onClick={(e) => applyFilters(filters)} className={classNames("px-4 py-2 text-sm font-medium text-white", blueBgClr)}>
                                            Apply
                                        </button>
                                        {/* &nbsp;
                                        <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Reset
                                        </button>{" "} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
