import React, { useRef } from "react";
import Currency from "react-currency-formatter";
import Moment from "react-moment";

import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { jsPDF } from "jspdf";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
import moment from "moment";

export default function ViewPaystub(props: { paystub: any; employee: any }) {
    console.log(props.employee);

    // const myContainer = useRef(null);

    // const pxTomm =()  => (px) {
    //     return Math.floor(px / $('#my_mm').height());
    //   }

    const onGeneratePDF = () => {
        console.log("onGeneratePDF");
        const input = document.getElementById("paystubContainer")!;
        // const portalDiv = document.getElementById('paystubContainer')!;
        const divHeight = input?.clientHeight;
        const divWidth = input?.clientWidth;
        const ratio = divHeight / divWidth;

        // htmlToImage.toPng(portalDiv, { quality: 0.95 })
        // .then(function (dataUrl) {
        //   var link = document.createElement('a');
        //   link.download = 'my-image-name.jpeg';

        // //   const pdf = new jsPDF();
        // //   pdf.addImage(dataUrl, 'PNG', 0, 0);
        // var pdf = new jsPDF("p", "mm", "a4");

        //   pdf.save("download.pdf");
        // });

        html2canvas(input, { scale: 5 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png", 1.0);
            const pdf = new jsPDF({
                //   orientation: 'landscape',
            });
            const imgProps = pdf.getImageProperties(imgData);
            const Width = pdf.internal.pageSize.getWidth();
            let Height = (imgProps.height * Width) / imgProps.width;
            Height = ratio * Width;

            var img_w = canvas.width;
            var img_h = canvas.height;

            pdf.addImage(imgData, "JPEG", -105, 0, Width * 2, Height * 2);
            pdf.save(props.employee.name + " - " + moment(props.paystub.check_date).format("YYYY/MM/DD") + ".pdf"); //Download the rendered PDF.

            //Convert to mm
            // var doc_w = ExportModule.pxTomm(img_w);
            // var doc_h = ExportModule.pxTomm(img_h);

            // var pdfWidth = pdf.internal.pageSize.getWidth();
            //   var pdfHeight = pdf.internal.pageSize.getHeight();
            // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            // pdf.save('download.pdf');
        });
    };

    const toUTCFormat = (d: any) => {
        // console.log(d);
        return moment.utc(moment(d)).format("MM/DD/YYYY");
        // return moment(d).add(1, "day").format();
    };

    return (
        // h-screen flex flex-col items-center justify-center bg-purple-200
        // ref={myContainer}
        <div className="section-to-print">
            <div className="px-0 py-0 sm:px-0 lg:px-0 ">
                <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                    <div className="flex justify-left">
                        <img className="h-20 mx-auto mt-0 ml-0" src="/mti-logo.png" alt="MTINC" />
                    </div>
                    <br />
                </div>
                {/* <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                    <div className="flex justify-left">
                        <img className="h-20 mx-auto mt-4 ml-0" src="/mti-logo.png" alt="MTINC" />
                    </div>

                    <div className="flex justify-end mr-0">
                        {" "}
                        <div className="px-0 py-1 sm:flex sm:items-center sm:px-0 lg:px-0 ">
                            <div className=" sm:flex-auto">
                                <p className="font-semibold text-gray-900 text-center-x">MindTech Inc</p>
                                <p className="mt-0 text-xs text-gray-700 text-center-x">10998 S. Wilcrest Suite-272</p>
                                <p className="mt-0 text-xs text-gray-700 text-center-x">Houston TX - 77099</p>
                                <p className="mt-0 text-xs text-gray-700 text-center-x">PH : 646-790-9628</p>
                                <p className="mt-0 text-xs text-gray-700 text-center-x">Email : hr@mindtechinc.com</p>
                                <p className="mt-0 text-xs text-blue-700 text-center-x">
                                    <a href="https://mindtechinc.com" target="_blank">
                                        www.mindtechinc.com
                                    </a>
                                </p>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none "></div>
                            Generate PDF comes here
                        </div>
                    </div>
                </div> */}
                {/* <button className="btn" onClick={() => onGeneratePDF()}>Print</button> */}

                <div className="px-0 py-1 sm:flex sm:items-center sm:px-0 lg:px-0 ">
                    <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold text-gray-900 text-center-x " onClick={() => onGeneratePDF()}>
                            {props?.employee?.name}
                        </h1>
                        <p className="mt-0 text-xs text-gray-700 text-center-x">SSN - {props?.employee?.masked_ssn}</p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none ">
                        <p className="mt-0 text-xs text-right text-gray-700">
                            {" "}
                            <br />
                            Check Date -{" "}
                            <Moment filter={toUTCFormat} local>
                                {props.paystub.check_date}
                            </Moment>{" "}
                            <br />
                            Check Period -{" "}
                            <Moment filter={toUTCFormat} local>
                                {props.paystub._d_start}
                            </Moment>{" "}
                            to{" "}
                            <Moment filter={toUTCFormat} local>
                                {props.paystub._d_end}
                            </Moment>{" "}
                            <br />
                        </p>
                    </div>
                </div>

                <hr className="pb-2 text-bold"></hr>

                <div className="flex flex-col mt-2 ">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 ">
                            <h2 className="pb-1 font-bold">Earnings</h2>
                            <div className="w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300 ">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">
                                                Type
                                            </th>

                                            <th scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">
                                                Regular
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <Currency quantity={props.paystub.e_regular || 0} currency="USD" />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">
                                                Total Earnings
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                <Currency quantity={props.paystub.e_total || 0} currency="USD" />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <h2 className="pb-1 font-bold">Taxes</h2>
                            <div className="w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">
                                                Type
                                            </th>

                                            <th scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">
                                                Federal Income Tax
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <Currency quantity={props.paystub.t_federal || 0} currency="USD" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                Social Security
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <Currency quantity={props.paystub.t_ssn || 0} currency="USD" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                Medicare
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <Currency quantity={props.paystub.t_medicare || 0} currency="USD" />
                                            </td>
                                        </tr>

                                        {props.paystub.t_gasit > 0 && (
                                            <tr>
                                                <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                    GA SIT
                                                </td>
                                                <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                    <Currency quantity={props.paystub.t_gasit || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )}

                                        {props.paystub.t_wa_ml > 0 && (
                                            <tr>
                                                <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                    Wa ML
                                                </td>
                                                <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                    <Currency quantity={props.paystub.t_wa_ml || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )}

                                        {props.paystub.t_wa_fl > 0 && (
                                            <tr>
                                                <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                    Wa FL
                                                </td>
                                                <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                    <Currency quantity={props.paystub.t_wa_fl || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )}

                                        {props.paystub.t_wa_ltc > 0 && (
                                            <tr>
                                                <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                    Wa LTC
                                                </td>
                                                <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                    <Currency quantity={props.paystub.t_wa_ltc || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )}

                                        {props.paystub.t_wa_wc > 0 && (
                                            <tr>
                                                <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                    Wa WC
                                                </td>
                                                <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                    <Currency quantity={props.paystub.t_wa_wc || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )}

                                        {props.paystub.t_nc > 0 && (
                                            <tr>
                                                <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                    North Carolina State Income
                                                </td>
                                                <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                    <Currency quantity={props.paystub.t_nc || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">
                                                Total Earnings
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                <Currency quantity={props.paystub.t_total || 0} currency="USD" />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <h2 className="pb-1 font-bold">Employee Deductions</h2>
                            <div className="w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">
                                                Type
                                            </th>

                                            <th scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-6 w-[500px]">
                                                Health Insurance
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <Currency quantity={props.paystub.d_insurance || 0} currency="USD" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                Dental Pre-Tax
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <Currency quantity={props.paystub.d_dental || 0} currency="USD" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                Vision Pre-Tax
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                <Currency quantity={props.paystub.d_vision || 0} currency="USD" />
                                            </td>
                                        </tr>

                                        {props.paystub.d_401k > 0 && (
                                            <tr>
                                                <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                    Traditional 401(k)
                                                </td>
                                                <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                    <Currency quantity={props.paystub.d_401k || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )}
                                        {props.paystub.d_irs > 0 && (
                                            <tr>
                                                <td scope="col" className="py-2 pl-4 pr-3 text-sm font-normal text-left text-gray-900 sm:pl-6">
                                                    ROTH / IRS
                                                </td>
                                                <td scope="col" className="px-3 py-2 text-sm font-normal text-left text-gray-900">
                                                    <Currency quantity={props.paystub.d_irs || 0} currency="USD" />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td scope="col" className="py-0 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[500px]">
                                                Total Earnings
                                            </td>
                                            <td scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                                                <Currency quantity={props.paystub.d_total || 0} currency="USD" />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-0 align-middle md:px-6 lg:px-8">
                            <div className="w-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full text-white bg-gray-700 divide-y divide-gray-300">
                                    <tfoot>
                                        <tr>
                                            <td scope="col" className="py-2 pl-4 pr-3 text-left text-md font-extrabold  sm:pl-6 w-[500px]">
                                                Net Pay
                                            </td>
                                            <td scope="col" className="px-3 py-2 font-semibold text-left text-md">
                                                <Currency quantity={props.paystub.net_pay || 0} currency="USD" />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
