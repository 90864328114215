/* This example requires Tailwind CSS v2.0+ */
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { getbillStats, getDashboardEmployeesStats, getDashboardFinanceStats, getProjectCountStats } from "../api/dashboard-api";
import Currency from "react-currency-formatter";
import classNames from "classnames";
import { blueBgClr } from "../constants/common";

// const stats = [
//     { name: "Employees", stat: "35", previousStat: "47", change: "20.5%", changeType: "increase" },
//     { name: "Projects", stat: "35", previousStat: "", change: "", changeType: "", postStat: "active" },
//     { name: "Billing", stat: "5816", previousStat: "5614", change: "2.02%", changeType: "increase", postTitle: "(USD)" },
//     { name: "Payments", stat: "24.57%", previousStat: "28.62%", change: "4.05%", changeType: "decrease", postTitle: "(USD)" },
// ];

export default function Stats() {
    const [stats, setStats] = useState<any>([]);

    useEffect(() => {
        setStats((stats) => []);

        getDashboardEmployeesStats().then((result: any) => {
            setStats((stats) => stats.concat({ name: "Active Employees", stat: result.length, previousStat: null, change: null, changeType: "increase", postStat: "count", index: 1, isCurrency: false }));
        });

        getProjectCountStats().then((result: any) => {
            setStats((stats) => stats.concat({ name: "Inforce Projects", stat: result.length, previousStat: "", change: "", changeType: "", postStat: "active", index: 2, isCurrency: false }));
        });

        getDashboardFinanceStats().then((result: any) => {
            
            setStats((stats) => stats.concat({ name: "Payments", stat: result[0].sum_of_payment, previousStat: null, change: null, changeType: "decrease", postStat: "", postTitle: "(USD)", index: 4, isCurrency: true }));
        });
        getbillStats().then((result:any)=>{
            console.log('res',result)
            setStats((stats) => stats.concat({ name: "Billing", stat: result[0].bill, previousStat: null, change: null, changeType: "increase", postStat: "", postTitle: "(USD)", index: 3, isCurrency: true }));
        })
    }, []);

    return (
        <>
            <div className="pt-0">
                {/* <h3 className="text-lg font-medium leading-6 text-gray-900">Last 30 days</h3> */}
                <dl className="grid grid-cols-1 mt-0 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow md:grid-cols-4 md:divide-y-0 md:divide-x">
                    {stats
                        .sort((a, b) => a.index - b.index)
                        .map((item, index) => (
                            <div key={nanoid()} className="px-4 py-0 sm:p-6">
                                <dt className="text-sm font-normal text-gray-500">
                                    {/* {item} */}
                                    {item?.name} <small className="text-xs font-medium text-gray-500">{item?.postTitle}</small>{" "}
                                </dt>
                                <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
                                    <div className={classNames("flex items-baseline text-2xl font-semibold text-blue-800")}>
                                        {item.isCurrency === true && (
                                            <>
                                                <Currency quantity={item.stat || 0} currency="USD" />
                                                <small className="pl-1 text-xs font-medium text-gray-500"> {item?.postStat}</small>
                                            </>
                                        )}

                                        {!item.isCurrency === true && (
                                            <>
                                                {item?.stat} <small className="pl-1 text-xs font-medium text-gray-500"> {item?.postStat}</small>
                                            </>
                                        )}

                                        {item?.previousStat && <span className="ml-2 text-sm font-medium text-gray-500">from {item?.previousStat}</span>}
                                    </div>

                                    {item.change && (
                                        <div className={classNames(item.changeType === "increase" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800", "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0")}>
                                            {item.changeType === "increase" ? <ArrowSmUpIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" aria-hidden="true" /> : <ArrowSmDownIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" aria-hidden="true" />}

                                            <span className="sr-only">{item.changeType === "increase" ? "Increased" : "Decreased"} by</span>
                                            {item.change}
                                        </div>
                                    )}
                                </dd>
                            </div>
                        ))}
                </dl>
            </div>
        </>
    );
}
