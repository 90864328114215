import React, { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm, useWatch, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import CurrencyField from "../components/currencyField";
import Currency from "react-currency-formatter";
import { addUpdateConsultant } from "../api/consultants-api";
import { useSnackbar } from "react-simple-snackbar";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import TotalCurrencyField from "../components/totalCurrencyField";
import { consultant } from "../model";

const schema = yup.object({
    e_regular: yup.number().required("*required"),
    d_insurance: yup.number().required("*required"),
    d_dental: yup.number().required("*required"),
    d_vision: yup.number().required("*required"),
    d_401k: yup.number().required("*required"),
    d_irs: yup.number().required("*required"),
    t_federal: yup.number().required("*required"),
    t_ssn: yup.number().required("*required"),
    t_medicare: yup.number().required("*required"),
    t_gasit: yup.number().required("*required"),
    t_wa_ml: yup.number().required("*required"),
    t_wa_fl: yup.number().required("*required"),
    t_wa_ltc: yup.number().required("*required"),
    t_wa_wc: yup.number().required("*required"),
    t_nc: yup.number(),
});

export default function PaystubDetails(props: { profile: any }) {
    const [openSnackbar, closeSnackbar] = useSnackbar();

    // console.log(props.profile.unstructured);

    let values: any = {};

    if (props.profile.unstructured) {
        values = JSON.parse(props.profile.unstructured)?.paystubAnnexure;
        // console.log(values);
    }

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            e_regular: 0,
            d_insurance: 0,
            d_dental: 0,
            d_vision: 0,
            d_401k: 0,
            d_irs: 0,
            t_federal: 0,
            t_ssn: 0,
            t_medicare: 0,
            t_gasit: 0,
            t_wa_ml: 0,
            t_wa_fl: 0,
            t_wa_ltc: 0,
            t_wa_wc: 0,
            t_nc: 0,
        },
        values,
    });

    const {
        register,
        handleSubmit,
        watch,
        control,
        reset,
        trigger,
        setValue,
        getValues,
        getFieldState,
        formState: { errors },
    } = methods;

    // const _control = useWatch({
    //     control,
    // });

    // const watchE = watch("e_regular");
    // const watchTF = watch("t_federal");

    React.useEffect(() => {
        const data = methods.watch();
        try {
            methods.setValue("e_total", (data.e_total = Number(data.e_regular)).toFixed(2));
            methods.setValue("t_total", (data.t_total = (Number(data.t_federal) + Number(data.t_ssn) + Number(data.t_medicare) + Number(data.t_gasit) + Number(data.t_wa_ml) + Number(data.t_nc) + Number(data.t_wa_fl) + Number(data.t_wa_ltc) + Number(data.t_wa_wc)).toFixed(2)));
            methods.setValue("d_total", (data.d_total = (Number(data.d_insurance) + Number(data.d_dental) + Number(data.d_vision) + Number(data.d_401k) + Number(data.d_irs)).toFixed(2)));
            methods.setValue("net_pay", (Number(data.e_total) - Number(data.d_total) - Number(data.t_total)).toFixed(2));
        } catch (e) {
            console.error(e);
        }
    }, [watch("e_regular"), watch("t_federal"), watch("t_ssn"), watch("t_medicare"), watch("t_gasit"), watch("t_wa_ml"), watch("t_wa_fl"), watch("t_wa_ltc"), watch("t_wa_wc"), watch("t_nc"), watch("    "), watch("d_dental"), watch("d_vision"), watch("d_401k"), watch("d_irs")]);

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        try {
            data.entity = getValues("entity"); // Ensure entity is included
            data.payroll_process = getValues("payroll_process");

            data.e_total = Number(data.e_regular).toFixed(2);
            data.d_total = (Number(data.d_insurance) + Number(data.d_dental) + Number(data.d_vision) + Number(data.d_401k) + Number(data.d_irs)).toFixed(2);
            data.t_total = (Number(data.t_federal) + Number(data.t_ssn) + Number(data.t_medicare) + Number(data.t_gasit) + Number(data.t_wa_ml) + Number(data.t_wa_fl) + Number(data.t_wa_ltc) + Number(data.t_wa_wc) + Number(data.t_nc)).toFixed(2);
            data.net_pay = (Number(data.e_total) - Number(data.d_total) - Number(data.t_total)).toFixed(2);
        } catch (e) {
            console.error(e);
        }

        let consultantData: consultant = {
            // ...props.profile, FIXME

            // dob: moment(data?.dob).format(format2),

            uuid: props.profile.uuid,
            emp_id: props.profile.emp_id,
            active: props.profile.active,
            bgv: props.profile.bgv,
            dob: props.profile.dob,
            email: props.profile.email,
            name: props.profile.name,
            phone: props.profile.phone,
            designation: props.profile.designation,
            status: props.profile.status,
            bgv_by: props.profile.bgv_by,
            bgv_dt: props.profile.bgv_dt,
            street: props.profile.street,
            city: props.profile.city,
            district: props.profile.district,
            state: props.profile.state,
            country: props.profile.country,
            zip: props.profile.zip,
            unstructured: JSON.stringify({
                paystubAnnexure: data,
            }),
            oid: props.profile.oid,
            ooid: props.profile.ooid,
            masked_ssn: props.profile.masked_ssn,
            attributes: props.profile.attributes,
            type: props.profile.type,
            sub_type: props.profile.sub_type,
            entity: data.entity,
            payroll_process: data.payroll_process,
            exit_dt: props.profile.exit_dt,
            fixed_payroll: props.profile.fixed_payroll,

            date_of_joining: props.profile.date_of_joining,
            date_of_exit: props.profile.date_of_exit,
        };

        // Calculate Totals
        addUpdateConsultant(consultantData).then((result: any) => {
            console.log(result);
            openSnackbar(result?.error ? result?.error?.sqlMessage : "Paystub details updated.");
        });
    };

    useEffect(() => {
        console.log(props.profile);
        // reset(props.profile);
    }, [methods.reset]);

    const payrollProcess = watch("payroll_process");

    return (
        <div>
            <div className="space-y-6">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="px-4 py-5 mt-5 bg-white shadow sm:rounded-lg sm:p-6">
                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                <div className="md:col-span-1">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Entity</h3>
                                    <p className="mt-1 text-xs text-gray-500"></p>
                                </div>
                                <div className="mt-5 md:mt-0 md:col-span-2">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="entity" className="block text-sm font-medium text-gray-700">
                                                Entity
                                            </label>
                                            <select id="entity" {...register("entity")} className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                <option value="MTINC">MindTech Inc</option>
                                                <option value="MTIND">MindTech India Pvt Ltd</option>
                                            </select>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="payroll_process" className="block text-sm font-medium text-gray-700">
                                                Process Payroll
                                            </label>
                                            <select id="payroll_process" {...register("payroll_process")} className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                <option value="1">W2</option>
                                                <option value="0">1099</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {payrollProcess !== "0" && ( // Hide if payroll process is "1099"
                            <div className="px-4 py-5 mt-5 bg-white shadow sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Earnings</h3>
                                        <p className="mt-1 text-xs text-gray-500"></p>
                                    </div>
                                    <div className="mt-5 md:mt-0 md:col-span-2">
                                        <div className="grid grid-cols-6 gap-0 text-right">
                                            <div className="col-span-6 sm:col-span-3">
                                                {/*  */}
                                                <CurrencyField label={"Regular"} attribute={"e_regular"} />
                                            </div>
                                            {/* <p>{errors?.e_regular?.message}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <Currency register={"e_total"} quantity={values.e_total || 0} currency="USD" /> */}

                        {/* <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-1">
                                <span className="ml-5 text-sm font-normal">
                                    <CurrencyField label={"Total Earnings:"} attribute={"e_total"} disabled={true} labelClass="font-extrabold" inputClass="bg-slate-200 " />
                                </span>
                            </div>
                        </div> */}

                        {payrollProcess !== "0" && ( // Hide if payroll process is "1099"
                            <div className="px-4 py-5 mt-5 bg-white shadow sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Taxes</h3>
                                        <p className="mt-1 text-xs text-gray-500"></p>
                                    </div>
                                    <div className="mt-5 md:mt-0 md:col-span-2">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-3">
                                                <CurrencyField label={"Federal Income Tax"} attribute={"t_federal"} />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <CurrencyField label={"Social Security"} attribute={"t_ssn"} />
                                            </div>{" "}
                                            <div className="col-span-6 sm:col-span-3">
                                                <CurrencyField label={"Medicare"} attribute={"t_medicare"} />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <CurrencyField label={"GA SIT"} attribute={"t_gasit"} />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <CurrencyField label={"WA ML"} attribute={"t_wa_ml"} />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <CurrencyField label={"WA FL"} attribute={"t_wa_fl"} />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <CurrencyField label={"WA LTC"} attribute={"t_wa_ltc"} />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <CurrencyField label={"WA WC"} attribute={"t_wa_wc"} />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <CurrencyField label={"North Carolina State Income"} attribute={"t_nc"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <Currency quantity={values.t_total || 0} currency="USD" /> */}
                        {/* <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-1">
                                <span className="ml-5 text-sm font-normal">
                                    <CurrencyField label={"Total Taxes:"} attribute={"t_total"} disabled={true} labelClass="font-extrabold" inputClass="bg-slate-200 " />
                                </span>
                            </div>
                        </div> */}
                        {payrollProcess !== "0" && ( // Hide if payroll process is "1099"
                            <div className="px-4 py-5 mt-5 bg-white shadow sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Deductions</h3>
                                        <p className="mt-1 text-xs text-gray-500"></p>
                                    </div>
                                    <div className="mt-5 md:mt-0 md:col-span-2">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                                                <CurrencyField label={"Health Insurance"} attribute={"d_insurance"} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                                                <CurrencyField label={"Dental Pre-Tax"} attribute={"d_dental"} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                                <CurrencyField label={"Vision Pre-Tax"} attribute={"d_vision"} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3 lg:col-span-3"></div>

                                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                                <CurrencyField label={"401(k)"} attribute={"d_401k"} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                                <CurrencyField label={"ROTH / IRS"} attribute={"d_irs"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* bg-gray-100 outline-0 border-0 shadow-none ring-0 ring-transparent border-hidden */}
                        {/* <Currency quantity={values.d_total || 0} currency="USD" /> */}
                        {/* <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-1">
                                <span className="ml-5 text-sm font-normal">
                                    <CurrencyField label={"Total Deductions:"} attribute={"d_total"} disabled={true} labelClass="font-extrabold" inputClass="bg-slate-200-x bg-gray-100 outline-0 border-0 shadow shadow-transparent shadow-none ring-0 ring-transparent border-hidden" />
                                </span>
                            </div>
                        </div> */}
                        {payrollProcess !== "0" && ( // Hide if payroll process is "1099"
                            <div className="px-4 py-5 mt-5 bg-white shadow sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Total</h3>
                                        <p className="mt-1 text-xs text-gray-500"></p>
                                    </div>
                                    <div className="mt-5 md:mt-0 md:col-span-2">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div className="col-span-6 sm:col-span-2">
                                                <TotalCurrencyField label={"Gross Earnings:"} attribute={"e_total"} disabled={true} labelClass="font-medium" inputClass="bg-white outline-0 border-0 shadow-none ring-0 ring-transparent border-hidden" />
                                                <TotalCurrencyField label={"Total Taxes:"} attribute={"t_total"} disabled={true} labelClass="font-medium" inputClass="bg-white outline-0 border-0 shadow-none ring-0 ring-transparent border-hidden" />
                                                <TotalCurrencyField label={"Total Deductions:"} attribute={"d_total"} disabled={true} labelClass="font-medium" inputClass="bg-white outline-0 border-0 shadow-none ring-0 ring-transparent border-hidden" />
                                                <hr />
                                                <TotalCurrencyField label={"Net Pay:"} attribute={"net_pay"} disabled={true} labelClass="font-extrabold" inputClass="bg-white outline-0 border-0 shadow-none ring-0 ring-transparent border-hidden font-extrabold" />
                                                {/* <CurrencyField label={"Net Pay"} attribute={"net_pay"} disabled={true} labelClass="font-extrabold" inputClass="bg-slate-200 " /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="flex justify-end pt-10 pb-10">
                            <Link to="/employees">
                                <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Cancel
                                </button>
                            </Link>
                            <button type="submit" className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Save
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
}

function NestedInput() {
    const { register } = useFormContext(); // retrieve all hook methods
    return <input {...register("e_total")} />;
}
