import React, { useState } from "react";
import Breadcrums from "../components/breadcrums";
import classNames from "classnames";
import { Link } from "react-router-dom";
import PageTitle from "../components/pageTitle";
import { payrollBreadcrums } from "../constants/breadcrums";
import { payrollCommandActions, button, buttonBase, alternativeBgClr, blueBgClr, sectionClass } from "../constants/common";
import { nanoid } from "nanoid";
import { savePayroll } from "../api/payroll-api";
import { PayrollModel } from "../model";

import { useSnackbar } from "react-simple-snackbar";
import moment from "moment";

export default function ImportPayroll() {
    const [file, setFile] = useState();
    const [array, setArray] = useState<PayrollModel[]>([]);

    const [openSnackbar, closeSnackbar] = useSnackbar();

    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const csvFileToArray = (string) => {
        const csvHeader = string
            .slice(0, string.indexOf("\n"))
            .split(",")
            .map((header) => header.trim())
            .filter((header) => header !== ""); // Exclude empty headers

        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

        const array = csvRows
            .map((i) => {
                const values = i.split(",");
                const obj = csvHeader.reduce((object, header, index) => {
                    if (["name", "payroll_process", "entity", "active"].includes(header)) return object; // Exclude specific columns

                    let value = values[index]?.trim() || "";

                    // Convert known date fields using Moment.js
                    if (["_d_start", "_d_end", "check_date"].includes(header)) {
                        value = formatMySQLDate(value);
                    }

                    object[header] = value;

                    return object;
                }, {} as Record<string, string>);

                const activeIndex = csvHeader.indexOf("active");
                return values[activeIndex]?.trim() === "1" ? obj : null;
            })
            .filter(Boolean); // Remove null values (inactive records)

        setArray(array);
    };

    const formatMySQLDate = (dateStr: string) => {
        return moment(dateStr, "MM/DD/YYYY").format("YYYY-MM-DD"); // Convert MM/DD/YYYY to YYYY-MM-DD
    };

    function handleOnSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();

        console.log("Importing ...");

        if (file) {
            fileReader.onload = function (event: any) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);

            var anyError = false;

            const promises = array.map((item: any) => {
                console.info(item);
                if (item.uuid) {
                    return savePayroll(item)
                        .then((result: any) => {
                            console.log(result); // success
                            return { success: true };
                        })
                        .catch((error: any) => {
                            console.error("Unable to save Consultant record - ", item);
                            return { success: false };
                        });
                }
                return Promise.resolve({ success: true });
            });

            Promise.allSettled(promises).then((results) => {
                anyError = results.some((res) => res.status === "rejected" || (res.value && !res.value.success));
                if (anyError) {
                    console.error("There were errors in processing the records.");
                    openSnackbar("There were errors in processing the records, please check logs!");
                } else {
                    console.log("All records processed successfully.");
                    openSnackbar("All records processed successfully.");
                }
            });

            // openSnackbar(anyError ? "Operation completed with some error!" : "Operation Completed!");

            console.log("Operation Completed.");
        }
    }

    const headerKeys = Object.keys(Object.assign({}, ...array));

    return (
        <>
            <div>
                <Breadcrums breadcrumLinks={payrollBreadcrums} commands={payrollCommandActions} module={"Payroll"} groups={["Actions", "Statement"]} />
                <PageTitle title={"Import Payroll"} preSummary={""} summary={"Import Payroll historical details."}></PageTitle>
            </div>

            <section className={sectionClass}>
                <form>
                    <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} />

                    <button
                        onClick={(e) => {
                            handleOnSubmit(e);
                        }}
                        className={classNames(button, buttonBase, blueBgClr)}
                    >
                        Import CSV File
                    </button>
                </form>

                <br />
            </section>

            <section>
                <table>
                    <thead>
                        <tr key={"header"}>
                            {headerKeys.map((key) => (
                                <th key={nanoid()}>{key}</th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {array.map((item) => (
                            <tr key={nanoid()}>
                                {Object.values(item).map((val: any) => (
                                    <td key={nanoid()}>{val}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

            <section className={sectionClass}>
                <a href="https://mtinc786.sharepoint.com/:x:/s/folio/EfPthI5UuHNCggfilaJTRRoBy0v1CMT3lP7TUmlB5SXOeg?e=2WSlhQ" download target="_blank" className="text-blue-900">
                    Sample CSV
                </a>
            </section>
        </>
    );
}
