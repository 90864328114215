import moment from "moment";

export async function addUpdateConsultant(consultantData) {
    console.log(consultantData);

    const rawResponse = await fetch(process.env.REACT_APP_API_URL + "/api/consultant", {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(consultantData),
    });
    const content = await rawResponse.json();
    return content;
}
export async function DeactivateActivateConsultant(uuid: any, active: number) {
    console.log(uuid);
    let _q = "UPDATE consultant SET  `active`=" + active + " where uuid='" + uuid + "'";
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["consultant"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function listOfEmployees() {
    try {
        // const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant?_where=(active,eq,1)&_sort=name");
        // return await response.json();

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    query: "SELECT * FROM consultant order by name;",
                    params: ["consultant"],
                }),
            });
            return await response.json();
        } catch (error) {
            return [];
        }
    } catch (error) {
        return [];
    }
}

export async function listIkatEmployees() {
    try {
        // const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant?_where=(active,eq,1)~and(project,eq,IKAT)&_sort=name");
        // return await response.json();
        let _q = "SELECT c.uuid AS uuid, p.uuid AS project_uuid, pm.pid AS project_master_pid, c.name as name,cm.name as client,c.name as  cname,pm.name AS project,p.*,pm.*, cm.*,c.* FROM consultant AS c LEFT JOIN project AS p ON c.uuid = p.uuid AND p.active = true LEFT JOIN project_master AS pm  ON p.pid = pm.pid LEFT JOIN client_master as cm ON pm.cid = cm.id";
        _q += " where  pm.name = 'IKAT'";
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    query: _q,
                    params: ["consultant"],
                }),
            });
            return await response.json();
        } catch (error) {
            return [];
        }
    } catch (error) {
        return [];
    }
}

export async function listOfEmployeesValidPaystub(filters: any) {
    let _q = "SELECT c.uuid AS uuid, p.uuid AS project_uuid, pm.pid AS project_master_pid, c.name as name,cm.name as client,c.name as  cname,pm.name AS project,p.*,pm.*, cm.*,c.* FROM consultant AS c LEFT JOIN project AS p ON c.uuid = p.uuid AND p.active = true LEFT JOIN project_master AS pm  ON p.pid = pm.pid LEFT JOIN client_master as cm ON pm.cid = cm.id";

    if (filters.activeStatus > 0) {
        _q += " and c.active = " + filters.activeStatus + " ";
    }

    _q += "and c.unstructured is not null and c.entity = 'MTINC'  ";

    _q += " where 1=1 ";
    _q += " and c.payroll_process = 1 and c.entity = 'MTINC' ";
    if (filters.activeStatus > 0) {
        _q += " and c.active = " + filters.activeStatus + " ";
    }
    _q += " order by c.name ";

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["consultant"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getFilteredEmployees(filters: any) {
    console.log(filters);

    let _q = "SELECT c.uuid AS uuid, p.uuid AS project_uuid, pm.pid AS project_master_pid, c.name as name,cm.name as client,c.name as  cname,pm.name AS project,p.*,pm.*, cm.*,c.* FROM consultant AS c LEFT JOIN project AS p ON c.uuid = p.uuid AND p.active = true LEFT JOIN project_master AS pm  ON p.pid = pm.pid LEFT JOIN client_master as cm ON pm.cid = cm.id";
    _q += " where 1=1 ";

    // Load only MTINC Employees
    _q += " and c.entity='MTINC' ";

    if (filters.activeStatus >= 0) {
        _q += "  and c.active = " + filters.activeStatus + "";
    }
    _q += " order by c.name ";
    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["consultant"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export function getName(list: any, uuid: any) {
    if (list.length > 0) {
        return list.filter((v) => v.uuid === uuid)[0].name;
    } else {
        return uuid;
    }
}

export function getEmployeeRecord(list: any, uuid: any) {
    if (list.length > 0) {
        return list.filter((v) => v.uuid === uuid)[0];
    } else {
        return uuid;
    }
}

export async function getProfile(uuid: any) {
    try {
        let _q = "SELECT c.uuid AS uuid, p.uuid AS project_uuid, pm.pid AS project_master_pid, c.name as name,cm.name as client,c.name as  cname,pm.name AS project,p.*,pm.*, cm.*,c.* FROM consultant AS c LEFT JOIN project AS p ON c.uuid = p.uuid AND p.active = true LEFT JOIN project_master AS pm  ON p.pid = pm.pid LEFT JOIN client_master as cm ON pm.cid = cm.id";
        if (uuid) {
            _q += " where  c.uuid = '" + uuid + "'";
        }
        console.log(_q);
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["consultant"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getProfileByUUID(uuid: any) {
    console.log(uuid);
    let _q = "SELECT c.uuid AS uuid, p.uuid AS project_uuid, pm.pid AS project_master_pid, c.name as name,cm.name as client,c.name as  cname,pm.name AS project,p.*,pm.*, cm.*,c.* FROM consultant AS c LEFT JOIN project AS p ON c.uuid = p.uuid AND p.active = true LEFT JOIN project_master AS pm  ON p.pid = pm.pid LEFT JOIN client_master as cm ON pm.cid = cm.id";
    if (uuid) {
        _q += " where  c.uuid = '" + uuid + "'";
    }
    console.log(_q);
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["consultant"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getConsultantDetails(uuid: any) {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant_details?_where=(uuid,eq," + uuid + ")~and(active,eq,1)&_sort=type,sub_type");
        return await response.json();
    } catch (error) {
        return [];
    }
}
