import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

// Define types
interface Employee {
    uuid: string;
    name: string;
}

interface PunchedHourEntry {
    date: string; // ISO format "YYYY-MM-DD"
    hours: string;
    employeeId: string;
}

export default function TimesheetEditor() {
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null);
    const [date, setDate] = useState<Date>(new Date());
    const [currentMonth, setCurrentMonth] = useState<{ month: number; year: number }>({
        month: new Date().getMonth() + 1, // JavaScript months are 0-indexed
        year: new Date().getFullYear(),
    });
    const [punchedHours, setPunchedHours] = useState<PunchedHourEntry[]>([]);

    // Helper function to normalize date format
    const normalizeDate = (date: Date): string => date.toISOString().split("T")[0];

    // Fetch employees on mount
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dynamic`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        query: "SELECT uuid, name FROM consultant ORDER BY name;",
                        params: [],
                    }),
                });
                if (!response.ok) throw new Error("Failed to fetch employees");
                const data: Employee[] = await response.json();
                setEmployees(data);
                if (data.length > 0) setSelectedEmployee(data[0].uuid);
            } catch (error) {
                console.error("Error fetching employees:", error);
            }
        };
        fetchEmployees();
    }, []);

    // Fetch punched hours when employee or month changes
    useEffect(() => {
        const fetchPunchedHours = async () => {
            if (!selectedEmployee) return;
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/finance`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        currMonth: currentMonth.month,
                        currYear: currentMonth.year,
                        uuid: selectedEmployee,
                    }),
                });
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const data: PunchedHourEntry[] = await response.json();
                if (!Array.isArray(data)) throw new Error("Invalid data format received from API");
                setPunchedHours(data.map(entry => ({ ...entry, date: normalizeDate(new Date(entry.date)) })));
            } catch (error) {
                console.error("Error fetching punched hours:", error);
                setPunchedHours([]);
            }
        };
        fetchPunchedHours();
    }, [selectedEmployee, currentMonth]);

    return (
        <div>
            <h2>Timesheet Editor</h2>
            <label>Select Employee:</label>
            <select onChange={(e) => setSelectedEmployee(e.target.value)} value={selectedEmployee || ""}>
                {employees.map((employee) => (
                    <option key={employee.uuid} value={employee.uuid}>
                        {employee.name}
                    </option>
                ))}
            </select>
            
            <Calendar onChange={(newDate) => setDate(newDate as Date)} value={date} />
            
            <h3>Punched Hours</h3>
            <ul>
                {punchedHours.length > 0 ? (
                    punchedHours.map((entry) => (
                        <li key={entry.date}>
                            {entry.date}: {entry.hours} hours
                        </li>
                    ))
                ) : (
                    <p>No punched hours recorded for this period.</p>
                )}
            </ul>
        </div>
    );
}